<template>
    <v-card @click="toggleDetail" :class="{'el_noimg': imgUrl == ''}" class="bl_card bl_card__ws">
        <div class="mt-6 mb-0 mx-4 bl_card__ws_date">
            <time datetime="2023-5-24">
                <span class="bl_card__ws_date_nen primary--text">{{classNen}}</span>
                <span class="bl_card__ws_date_tsuki primary--text">{{classTsuki}}<small>/</small></span>
                <span class="bl_card__ws_date_hi primary--text">{{classHi}}</span>
                <span class="bl_card__ws_date_youbi primary--text">{{classYoubi}}</span>
                <div class="bl_card__ws_date_kaisai">{{ statusText }}</div>
            </time>
        </div>
        <img :src="setImage" class="bl_card__ws_img"/>
        <v-card-title class="mt-0 bl_card__ws_content">
            <h4 @click="ActiveDetail()">{{title}}</h4>
            <!-- 会場 -->
            <span class="bl_card__ws_place">
                <a v-if="location == 'ten'" href="https://artstudio10.hp.peraichi.com/" target="_blank">@ART STUDIO TEN</a>
                <a v-else-if="location == 'ishikura'" href="https://goo.gl/maps/LT7zoMM3NEKMn4Fn7" target="_blank">@石蔵Shiten</a>
                <a v-else-if="location == 'tsukuru'" href="https://hifumian.com/" target="_blank">@つくるひとcafe</a>
                <a v-else-if="location == 'nagamine'" href="https://www.nagamine-tsuchiura.com/" target="_blank">@土浦市ふれあいセンターながみね</a>
                <a v-else>@未定</a>
            </span>
            <span class="bl_card__ws_time">{{classTime}}</span>
            <div class="my-2 bl_card__ws_tagWrapper">
                <span v-for="item in tagList" :key="item.index">
                    {{ item }}
                </span>
            </div>
        </v-card-title>

        <v-card-actions class="py-2 px-4 bl_card__ws_detail">
            <div>
                <v-btn @click="ActiveDetail()" text small class="bl_card__ws_detail_btn">
                    <span class="bl_card__ws_detail_btn_text_inactive">詳しくはこちら</span>
                    <span class="bl_card__ws_detail_btn_text_active">閉じる</span>
                </v-btn>
            </div>
        </v-card-actions>
        <div class="bl_card__ws_detail_inner">
            <div>
                <slot></slot>
                <hr class="my-4">
                <div class="my-4">
                    <h5 class="mb-2 text-subtitle-2 font-weight-bold">会場について</h5>
                    <!-- TEN -->
                    <div v-if="location == 'ten'" class="bl_locationList">
                        <h4 class="mb-2 text-h7 font-weight-bold"><a href="https://artstudio10.hp.peraichi.com/" target="_blank">ART STUDIO TEN</a></h4>
                        <span>茨城県つくば市竹園2丁目4-10 村田ビル 201号</span>
                        <dl>
                            <dd>思いっきり絵を描きたい人におすすめのオシャレで居心地の良いレンタルアトリエ。
                            </dd>
                        </dl>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3224.6015005830495!2d140.11788945099215!3d36.07882278000584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60220df6721bb6cd%3A0xfd5c53e58cf787e4!2sart%20studio%20TEN!5e0!3m2!1sja!2sjp!4v1681197479481!5m2!1sja!2sjp" width="100%" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <!-- 石蔵 Shiten -->
                    <div v-else-if="location == 'ishikura'" class="bl_locationList">
                        <h4 class="mb-2 text-h7 font-weight-bold"><a href="https://goo.gl/maps/LT7zoMM3NEKMn4Fn7" target="_blank">石蔵Shiten</a></h4>
                        <span>茨城県つくば市神郡１１１</span>
                        <dl>
                            <dd>
                                石造の穀物倉庫を利用したレンタルスペース。筑波山がよく見えるテラスはゆったりとした時間が流れます。駐車場:市営神郡駐車場（無料）から歩いて2分ほどです。
                            </dd>
                        </dl>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3219.82969487801!2d140.09127665099513!3d36.19502347997998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6022060f01c3c785%3A0x1db917e061c9707a!2z55-z6JS1IFNoaXRlbjo!5e0!3m2!1sja!2sjp!4v1681198527944!5m2!1sja!2sjp" width="100%" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <!-- つくるひとcafe -->
                    <div v-if="location == 'tsukuru'" class="bl_locationList">
                        <h4 class="mb-2 text-h7 font-weight-bold"><a href="https://hifumian.com/" target="_blank">つくるひとcafe</a></h4>
                        <span>茨城県つくば市二の宮2-5-20</span>
                        <dl>
                            <dd>コワーキングカフェ併設のレンタルスペース。販売ボックスからレンタルキッチンまで、クラフト作家さん・飲食店のスタートアップ向けのサポートも充実しています。
                            </dd>
                        </dl>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3225.168594638298!2d140.1170204154247!3d36.06499168010667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x602273b684b6f675%3A0x6bac81a086226178!2z44CQ5YaN5bu6T1BFTu-8geOAkeOBpOOBj-OCi-OBsuOBqGNhZmXvvIjjgbLjgbXjgb_mnY_vvIk!5e0!3m2!1sja!2sjp!4v1681201854763!5m2!1sja!2sjp" width="100%" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <!-- ながみね -->
                    <div v-if="location == 'nagamine'" class="bl_locationList">
                        <h4 class="mb-2 text-h7 font-weight-bold"><a href="https://hifumian.com/" target="_blank">土浦市ふれあいセンターながみね</a></h4>
                        <span>茨城県土浦市中村西根２０７８−１</span>
                        <dl>
                            <dd>大角豆交差点近くクリーンセンター横の公共施設。土浦市ですがつくば市、阿見町からも通いやすい立地です。wi-fiはありません。
                            </dd>
                        </dl>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d25805.76551478997!2d140.154944!3d36.051522!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x602272c5761f30df%3A0x44a825eee46a34c!2z5Zyf5rWm5biC44G144KM44GC44GE44K744Oz44K_44O8IOOBquOBjOOBv-OBrQ!5e0!3m2!1sja!2sjp!4v1686461601624!5m2!1sja!2sjp" width="100%" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
            <v-btn 
            :href="reserveUrl" target="_blank"
            :disabled="statusText == '開催済' ||statusText == '準備中' || disabled"
            class="primary bl_card__ws_detail_reserve_btn">
                <span v-if="statusText == '準備中'">受付準備中</span>
                <span v-else-if="statusText == '開催済'">開催済</span>
                <span v-else>受付はこちら</span>
            </v-btn>
        </div> 
    </v-card>
</template>

<script>
export default {
    name: 'ClassCard',
    props:{
        isActive:{},
        classNen:{
            type: Number,
            required: true
        },
        classTsuki:{
            type: Number,
            required: true
        },
        classHi:{
            type: Number,
            required: true
        },
        classYoubi:{
            type: Number,
            required: true
        },
        statusText:{
            type: String,
        },
        imgUrl:{
            type: String,
        },
        reserveUrl:{
            type: String,
        },
        title:{
            type: String,
        },
        location:{
            type: String,
        },
        classTime:{
            type: String,
        },
        tagList:{
            type: Array,
        },
        disabled:{
            type: Boolean,
        },

    },
    computed:{
        setImage(){
            if(this.imgUrl){
                return 'img/direct_img/artclass_img/' + this.imgUrl
            }else{
                return 'img/direct_img/artclass_img/digitalOekakiWorkshop_00_blue_square.png'
            }
        }
    },
    data: () => ({
        //
    }),
    methods: {
    ActiveDetail(){
        this.$emit('active-detail')
    }
  }
};
</script>

<style scoped>
.bl_card.bl_card__ws {
    display: grid;
    grid-template-areas: 
    "bl_date "
    "bl_card__ws_img "
    "bl_content "
    "bl_detail ";
    grid-template-columns: minmax(220px, 100%);
    grid-template-rows: 70px auto auto 50px;
    width: 100%;
    align-items: flex-start;
}
.bl_card.bl_card__ws.isActive{
    width: 100%;
    grid-template-areas: 
    "bl_date  bl_detail_inner" 
    "bl_card__ws_img  bl_detail_inner" 
    "bl_content  bl_detail_inner"
    " bl_detail  bl_detail_inner";
    grid-template-columns: 250px auto;
    column-gap: 0;
} 
.bl_card__ws_date{
    grid-area: bl_date;
}
.bl_card.bl_card__ws .bl_card__ws_date time{
    display: grid;
    grid-template-areas: 
    "nen hi youbi kaisai"
    "tsuki hi youbi kaisai";
    grid-template-columns: auto auto auto 1fr;
    row-gap: 0;
    column-gap: 4px;
    align-items: center;
    width: 100%;
    font-weight: 600;
    font-size: 32px;
    line-height: 1;
}
.bl_card__ws_date_nen{
    grid-area: nen;
    font-size: .3em;
    text-align: right;
}
.bl_card__ws_date_tsuki{
    grid-area: tsuki;
    font-size: .7em;
    text-align: right;
}
.bl_card__ws_date_hi{
    grid-area: hi;
    font-size: 1em;
    text-align: right;
    padding-right: 4px;
}
.bl_card.bl_card__ws .bl_card__ws_date_youbi{
    grid-area: youbi;
    display: inline-flex;
    font-size: .25em;
    justify-self: baseline;
}
.bl_card__ws_date_kaisai{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    grid-area: kaisai;
    font-size: .45em;
    letter-spacing: 0.1em;
    white-space: nowrap;
}
.bl_card__ws_content{
    grid-area: bl_content;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: 
    " bl_content_h4 "
    " bl_card__ws_place "
    " bl_card__ws_time "
    " bl_card__ws_tagWrapper";
    min-width: 200px;
    row-gap: 8px;
}
.bl_card.bl_card__ws img.bl_card__ws_img{
    grid-area: bl_card__ws_img;
    width: 100%;
    padding: 12px;
}
.el_noimg.bl_card.bl_card__ws img.bl_card__ws_img{
    opacity: .5;
    position: relative;
}
.el_noimg.bl_card.bl_card__ws img.bl_card__ws_img:before{
    content: "Drawing..";
    display:flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.bl_card.bl_card__ws .bl_card__ws_content  h4{
    grid-area: bl_content_h4;
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 24px;
    cursor: pointer;
}
.bl_card.bl_card__ws .bl_card__ws_place{
    grid-area: bl_card__ws_place;
    font-size: 14px;
    font-weight: 600;
    line-height: 12px;
    padding-right: 8px;
}
.bl_card.bl_card__ws .bl_card__ws_time{
    grid-area: bl_card__ws_time;
    font-size: 12px;
    line-height: 12px;
    padding-right: 8px;
}
.bl_card.bl_card__ws .bl_card__ws_tagWrapper{
    grid-area: bl_card__ws_tagWrapper;
    display: flex;
    flex-wrap: wrap;
    column-gap: 4px;
    row-gap: 6px;
    width: 100%;
}
.bl_card.bl_card__ws .bl_card__ws_tagWrapper>span{
    display: inline-flex;
    white-space: nowrap;
    padding: 4px 8px;
    font-size: 12px;
    line-height: 12px;
    background-color: #efefef;
}
.bl_card.bl_card__ws .bl_card__ws_detail{
    grid-area: bl_detail;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}
.bl_card.bl_card__ws .bl_card__ws_detail h4{
    font-size: 1em;
    line-height: 1;
    text-align: center;
}
.bl_card.bl_card__ws.isActive .bl_card__ws_detail_btn_text_inactive,
.bl_card.bl_card__ws .bl_card__ws_detail_btn_text_active{
    display: none;
}
.bl_card.bl_card__ws .bl_card__ws_detail_btn_text_inactive,
.bl_card.bl_card__ws.isActive .bl_card__ws_detail_btn_text_active{
    display: flex;
}
.bl_card.bl_card__ws .bl_card__ws_detail_inner{
    grid-area: bl_detail_inner;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    background-color: #16b3e317;
    transform: translateX(-100%);
    animation: sideanime .2s ease-out;
}
.bl_card.bl_card__ws.isActive .bl_card__ws_detail_inner{
    display: flex;
    width: 100%;
    padding: 28px;
    transform: translateX(0%);
}
@keyframes sideanime {
  from{
    transform: translateX(-15%);
  }
}
.bl_card.bl_card__ws .bl_card__ws_detail_reserve_btn{
    display: flex;
    width: fit-content;
}

@media screen and (max-width: 1280px) {
    .bl_card.bl_card__ws {
        grid-template-areas: 
        "bl_date  bl_card__ws_img bl_content "
        "bl_date bl_card__ws_img bl_detail ";
        grid-template-columns: 100px 200px 1fr;
        grid-template-rows: auto auto;
    }
    .bl_card.bl_card__ws.isActive{
        grid-template-areas: 
        "bl_date  bl_detail_inner" 
        "bl_card__ws_img  bl_detail_inner" 
        "bl_content  bl_detail_inner"
        " bl_detail  bl_detail";
        grid-template-columns: 2fr 3fr;
        grid-template-rows: 70px 300px 1fr 50px;
    }
    .bl_card.bl_card__ws .bl_card__ws_date time{
        display: grid;
        grid-template-areas: 
        "nen hi"
        "tsuki hi"
        "youbi youbi"
        "kaisai kaisai";
        grid-template-columns: auto auto;
        grid-template-rows: auto auto 16px 16px;
        row-gap: 0;
    }
    .bl_card.bl_card__ws.isActive .bl_card__ws_date time{
        grid-template-areas: 
        "nen hi youbi kaisai"
        "tsuki hi youbi kaisai";
        grid-template-columns: auto auto auto 1fr;
    }
    .bl_card__ws_date_kaisai{
        justify-content: center;
        margin-top: 8px;
    }
    .bl_card.bl_card__ws .bl_card__ws_date time .bl_card__ws_date_youbi{
        margin: 16px auto;
        text-align: center;
    }
    .bl_card.bl_card__ws .bl_card__ws_content{
        grid-template-areas: 
        " bl_content_h4 "
        " bl_card__ws_place "
        " bl_card__ws_time "
        " bl_card__ws_tagWrapper";
        
    }
    .bl_card.bl_card__ws .bl_card__ws_img{
        max-width: 300px;
        margin: 0 auto 12px;
    }
}
@media screen and (max-width: 1000px) {
    .bl_card.bl_card__ws .bl_card__ws_content img{
        max-width: 300px;
    }
    .bl_card.bl_card__ws.isActive{
        width: 100%;
        align-items: flex-start;
        grid-template-columns: 320px 1fr;
    }
}

@media screen and (max-width: 700px) {
    .bl_card.bl_card__ws {
        grid-template-areas: 
        "bl_date bl_card__ws_img"
        "bl_content bl_card__ws_img"
        "bl_detail bl_detail";
        grid-template-columns: auto 200px;
    }
    .bl_card.bl_card__ws.isActive{
        order: unset;
        display: grid;
        grid-template-areas: 
        "bl_date bl_card__ws_img" 
        "bl_content bl_card__ws_img" 
        "bl_detail bl_detail"
        "bl_detail_inner bl_detail_inner";
        width: 100%;
        align-items: flex-start;
        grid-template-columns: 1fr 200px;
        grid-template-rows: auto auto auto auto;
    }
    .bl_card.bl_card__ws .bl_card__ws_date time{
        grid-template-areas: 
        "nen hi youbi kaisai"
        "tsuki hi youbi kaisai";
        width: fit-content;
    }
    .bl_card__ws_date_kaisai{
        justify-content: flex-end;
        width: 100%;
        padding-left: 16px;
    }
    .bl_card.bl_card__ws img.bl_card__ws_img{
        max-width: 200px;
    }
}

@media screen and (max-width: 500px) {
    .bl_card.bl_card__ws {
        grid-template-areas: 
        "bl_date"
        "bl_card__ws_img"
        "bl_content"
        "bl_detail";
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto;
    }
    .bl_card.bl_card__ws.isActive{
        order: unset;
        display: grid;
        grid-template-areas: 
        "bl_date"
        "bl_card__ws_img" 
        "bl_content " 
        "bl_detail "
        "bl_detail_inner";
        width: 100%;
        align-items: flex-start;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto auto;
    }
    .bl_card.bl_card__ws img.bl_card__ws_img{
        max-width: 500px;
    }
}

</style>
